import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { loaderColors } from './loaderColors';

export const ChannelGroupLoader = (props: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width={220}
    height={122}
    className="mb-[12px]"
    viewBox="0 0 220 122"
    backgroundColor={loaderColors.backgroundColor}
    foregroundColor={loaderColors.foregroundColor}
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="220" height="26" />
    <rect x="0" y="40" rx="2" ry="2" width="170" height="22" />
    <rect x="0" y="84" rx="2" ry="2" width="170" height="22" />
  </ContentLoader>
);
