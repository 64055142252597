import { CircleClearButton } from '@foundationPathAlias/components';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { JumpToLatestButton } from './JumpToLatestButton';

import { classNames } from '@foundationPathAlias/utilities';
import { observer } from 'mobx-react-lite';

export function _EditorInfoBar() {
  const messageStore = useInjection(IOC_TOKENS.messageStore);

  let content;
  let cancelAction;

  const isEditing = messageStore?.editData?.active;
  const shouldShow = isEditing || messageStore?.replyModel;
  const showJumpToLatestButton = messageStore.showJumpToLatestButton;

  if (isEditing) {
    content = 'Editing message';
    cancelAction = messageStore.cancelEditMessage;
  }

  if (messageStore.replyModel) {
    content = (
      <p>
        Replying to:{' '}
        <b>{messageStore.replyModel.serverData.author?.username}</b>
      </p>
    );

    cancelAction = () => {
      messageStore.setReplyModel(null);
    };
  }

  const goToLatestContent = showJumpToLatestButton ? (
    <JumpToLatestButton onClick={messageStore.goToLatest} />
  ) : null;

  const mainElements = shouldShow ? (
    <div
      className={classNames(
        'bg-surface-mapping-secondary-tertiary',
        'rounded-t-[5px] border-[1px] border-b-0 border-element-subtle',
        'text-body16R text-text-primary',
        'py-[7px] pl-[16px] pr-[14px]',
        'flex h-[40px] w-full justify-between'
      )}
    >
      {content}
      <CircleClearButton onClick={cancelAction as any} />
    </div>
  ) : null;

  return (
    <div className="relative">
      {goToLatestContent}

      {mainElements}
    </div>
  );
}

export const EditorInfoBar = observer(_EditorInfoBar);
