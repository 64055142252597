import { classNames } from '@foundationPathAlias/utilities';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  CircleButtonBase,
  CircleButtonBasePropsType,
} from './CircleButtonBase';

export function CircleClearButton(
  props: Omit<CircleButtonBasePropsType, 'children'>
) {
  const { cn, ...otherProps } = props;
  return (
    <CircleButtonBase
      cn={classNames(
        'bg-hovered-selected dark:bg-hovered-selected-dark active:opacity-20',
        cn
      )}
      {...otherProps}
    >
      <XMarkIcon className="h-[10px] w-[10px] text-text-primary" />
    </CircleButtonBase>
  );
}
