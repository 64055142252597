import { User } from '@10x/foundation/types';
import {
  UserProfileCard,
  UserProfileTooltipWrapper,
} from '@mainApp/src/components/common/user-profile-card';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsAuthorTheSameUser } from './hooks';

export type MessageUserPanelPropsType = {
  username: string;
  avatarUrl?: string;
  date: string;
  justJoined?: boolean;
  author?: Pick<
    User,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'username'
    | 'bio'
    | 'website'
    | 'imageUrls'
  > | null;
};
export function _MessageUserPanel(props: MessageUserPanelPropsType) {
  const { username, date, justJoined, avatarUrl, author } = props;
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const {
    systemStore,
    dimensionsStore,
    interactiveStore,
    accountSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.accountSettingsStore,
  ]);

  const { t } = useTranslation('common');

  const isAuthorTheSameUser = useIsAuthorTheSameUser(author?.id);

  const handleCloseTooltip = () => {
    setTooltipOpen(false);
  };

  const cardProfileContent = (
    <UserProfileCard
      onClose={handleCloseTooltip}
      hideAction={true}
      hideMenu={true}
      darkMode={systemStore.isDarkTheme}
      user={
        author
          ? {
              ...author,
              avatarUrl,
            }
          : undefined
      }
      onSettings={() => {
        accountSettingsStore.setShow(true);
      }}
      ownProfile={isAuthorTheSameUser}
    />
  );

  return (
    <div className="flex items-center">
      {dimensionsStore.isMobile ? (
        <span
          onClick={() => {
            interactiveStore.setContent(cardProfileContent);
            interactiveStore.setBottomSheet(true);
          }}
          role="button"
          className="cursor-pointer text-body16SB text-text-primary"
        >
          {username}
        </span>
      ) : (
        <UserProfileTooltipWrapper
          open={tooltipOpen}
          onClose={handleCloseTooltip}
          onOpen={() => setTooltipOpen(true)}
          triggerEl={
            <span
              role="button"
              className="cursor-pointer text-body16SB text-text-primary"
            >
              {username}
            </span>
          }
        >
          {cardProfileContent}
        </UserProfileTooltipWrapper>
      )}

      {justJoined && (
        <span
          className="ml-[4px] text-body16R text-text-placeholder dark:text-text-placeholder-dark
      "
        >
          {t('justJoined')!}
        </span>
      )}
      <span className="mx-[4px] flex items-center text-sm14T text-text-secondary">
        •
      </span>
      <span className="text-sm14T text-text-secondary">{date}</span>
    </div>
  );
}

export const MessageUserPanel = observer(_MessageUserPanel);
