import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { OutlineButton, withAnimatedStack } from '@foundationPathAlias/main';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenIds as CapScreenIds } from '@mainApp/src/modules/customer-admin-panel/constants';
import { extractPlainText } from '@mainApp/src/utils/lexical';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RuleItem } from '../components/RuleItem';
import { RuleTitle } from '../components/RuleTitle';
import { ScreenIds } from '../constants';
import { useScreenRules } from '../ScreenRules.context';

const _AggregatedRulesScreen = () => {
  const { t } = useTranslation(['channel']);

  const { capStore, rulesStore, platformStore, communityStore } =
    useMultipleInjection([
      IOC_TOKENS.capStore,
      IOC_TOKENS.rulesStore,
      IOC_TOKENS.platformStore,
      IOC_TOKENS.communityStore,
    ]);

  const currentCommunityName =
    communityStore.activeCommunity?.data?.serverData?.name;

  const isUserAdmin = communityStore.activeCommunity.data?.isUserAdmin;

  const platformRulesList = platformStore.platformRules.data || [];

  const communityRulesList = rulesStore.communityRules;

  const { setCurrentRuleDetails, setCurrentPlatformRule, handleGoToScreen } =
    useScreenRules();

  useEffect(() => {
    platformStore.loadPlatformRules();
  }, []);

  useEffect(() => {
    if (rulesStore.activeCommunityId) {
      rulesStore.loadCommunityRules();
    }
  }, [rulesStore.activeCommunityId]);

  const handleCreateRules = () => {
    capStore.setActiveScreenId(CapScreenIds.RULES);
    capStore.setIsShow(true);
  };

  return (
    <div className="w-full overflow-auto">
      <div className="py-[24px] md:px-[12px]">
        <div className="md:flex md:justify-between">
          <RuleTitle
            title={t('rules.communityRules')}
            description={t('rules.theseRulesApplyTo', {
              communityName: currentCommunityName,
            })}
          />

          {isUserAdmin && (
            <div className="mt-[16px] px-[16px] md:mt-0 md:px-0">
              <OutlineButton
                cn="border-element-subtle text-sm14SB py-[8px] h-[40px]"
                onClick={handleCreateRules}
              >
                {t('rules.createOrEditRules')}
              </OutlineButton>
            </div>
          )}
        </div>

        {rulesStore.loadingRules && (
          <div className="my-[16px] flex justify-center">
            <SmallLoader />
          </div>
        )}

        {communityRulesList.map((communityRule) => (
          <RuleItem
            key={communityRule.id}
            title={communityRule.title}
            description={extractPlainText(communityRule.rawJSON) || ''}
            onClick={() => {
              setCurrentRuleDetails({
                id: communityRule.id,
                title: communityRule.title,
                rawJson: communityRule.rawJSON,
              });
              handleGoToScreen(ScreenIds.RULE_DETAILS);
            }}
          />
        ))}

        <hr className="mx-[16px] my-[24px] border-element-subtle md:mx-0" />

        <RuleTitle
          title={t('rules.platformRules')}
          description={t('rules.platformRulesDescription')}
        />

        {platformStore.platformRules.loading && (
          <div className="my-[16px] flex justify-center">
            <SmallLoader />
          </div>
        )}

        {platformRulesList.map((platformRule) => (
          <RuleItem
            key={platformRule.id}
            title={platformRule.title}
            description={extractPlainText(platformRule.rawJSON) || ''}
            onClick={() => {
              setCurrentPlatformRule(platformRule);
              handleGoToScreen(ScreenIds.SUB_RULES);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const AggregatedRulesScreen = withAnimatedStack(
  observer(_AggregatedRulesScreen)
);
