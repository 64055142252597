import { action, makeObservable, observable } from 'mobx';

import {
  DropupData,
  DropupTypeEnum,
  PopoverData,
  PopoverPlacementEnum,
  PopoverTypeEnum,
} from '../common/types';

export enum MESSAGE_EDITOR_EVENTS_ENUM {
  SELECT_MENTION_ADMIN = 'SELECT_MENTION_ADMIN',
}

const initialDropupData: DropupData = {
  show: false,
  type: DropupTypeEnum.EMPTY,
  searchCriteria: undefined,
};

const initialPopoverData = {
  show: false,
  type: PopoverTypeEnum.MENTION_USER,
  // html element on what the user is hovering
  triggerEl: null,
  loading: false,
  data: null,
  PopoverProps: {
    placement: PopoverPlacementEnum.RightEnd,
    offsetConfig: 5,
  },
};

export class MessageEditorStore {
  dropupData = initialDropupData;
  // for the mentions popover on hover
  popoverData: PopoverData = initialPopoverData;
  // lexical typeahead plugin fn
  selectOptionAndCleanUp: (data: any) => void = () => void 0;

  // it should store only subscribers and don't save any data
  // the data should go through notify -> subscribers. It's like event bus
  // through the app. I think should create the global event bus service
  subsRegistry: { [index: string]: ((data: PopoverData) => void)[] } = {};
  on = (eventName: MESSAGE_EDITOR_EVENTS_ENUM, cb: any) => {
    const subsArray = this.subsRegistry[eventName];
    if (subsArray) {
      subsArray.push(cb);
    } else {
      this.subsRegistry[eventName] = [cb];
    }
  };

  notify = (eventName: MESSAGE_EDITOR_EVENTS_ENUM, data: any) => {
    const subsArray = this.subsRegistry[eventName];
    if (subsArray && subsArray.length) {
      subsArray.forEach((sub) => sub(data));
    }
  };

  clear = (eventName: MESSAGE_EDITOR_EVENTS_ENUM) => {
    delete this.subsRegistry[eventName];
  };

  constructor() {
    makeObservable(this, {
      dropupData: observable,
      popoverData: observable,
      setSelectOptionAndCleanUp: action,
      setShowDropupData: action,
      hideDropup: action,
      setPopoverData: action,
      hidePopover: action,
    });
  }

  setSelectOptionAndCleanUp = (fn: (data: any) => void) => {
    this.selectOptionAndCleanUp = fn;
  };

  setShowDropupData = (data: DropupData) => {
    // probably the content shouldn't change without the show change
    if (data.show !== this.dropupData.show) {
      this.dropupData = data;
    }
  };

  // just a quick hide
  hideDropup = () => {
    if (this.dropupData.show) {
      this.dropupData = initialDropupData;
    }
  };

  setPopoverData = (data: PopoverData) => {
    // probably the content shouldn't change without the show change
    // if (data.show !== this.popoverData.show) {
    this.popoverData = {
      ...this.popoverData,
      ...data,
    };
    // }
  };

  // just a quick hide
  hidePopover = () => {
    if (this.popoverData.show) {
      this.popoverData = initialPopoverData;
    }
  };
}

export const messageEditorStore = new MessageEditorStore();
