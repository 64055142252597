import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import {
  MentionChannelNode,
  MentionUserNode,
} from '@mainApp/src/components/editor';
import { paths } from '@mainApp/src/config';
import { GlobalEvents } from '@mainApp/src/events';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { PopoverTypeEnum } from '@mainApp/src/modules/channel/common/types';
import { messageEditorStore } from '@mainApp/src/modules/channel/editor-area/MessageEditor.store';

export function _MessageEditorNodeEventPlugin() {
  const {
    userStore,
    eventBus,
    communityStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.userStore,
    IOC_TOKENS.eventBus,
    IOC_TOKENS.communityStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const router = useRouter();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isPointerOverRef = useRef(false);

  const channelRedirectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const userMentionMobilePointerUpRef = useRef<NodeJS.Timeout | null>(null);

  const processUser = useCallback(
    (id: string, el: HTMLElement) => {
      messageEditorStore.setPopoverData({
        show: true,
        type: PopoverTypeEnum.MENTION_USER,
        triggerEl: el,
        loading: true,
        data: null,
      });

      userStore.getUser(id).then((userModel) => {
        messageEditorStore.setPopoverData({
          show: true,
          type: PopoverTypeEnum.MENTION_USER,
          triggerEl: el,
          loading: false,
          data: userModel,
        });
      });
    },
    [userStore]
  );

  const messageListScrollHandler = useCallback(() => {
    if (channelRedirectTimeoutRef.current) {
      clearTimeout(channelRedirectTimeoutRef.current);
      channelRedirectTimeoutRef.current = null;
    }
    if (userMentionMobilePointerUpRef.current) {
      clearTimeout(userMentionMobilePointerUpRef.current);
      userMentionMobilePointerUpRef.current = null;
    }
  }, []);
  useEffect(() => {
    eventBus.on(GlobalEvents.MESSAGE_LIST_SCROLL, messageListScrollHandler);
    return () => {
      eventBus.removeListener(
        GlobalEvents.MESSAGE_LIST_SCROLL,
        messageListScrollHandler
      );
    };
  }, []);

  const handleUserMention = useCallback((e: Event) => {
    const el: HTMLElement | null = e?.target as any;

    const rect = el?.getBoundingClientRect();
    const virutalEl = { ...el, getBoundingClientRect: () => rect };
    const id = el?.dataset.id;

    if (!id || id === 'undefined') return;

    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        processUser(id, virutalEl as HTMLElement);

        timeoutRef.current = null;
      }, 350);
    }
  }, []);

  const userEventHandler = useCallback((e: Event) => {
    if (isPointerOverRef.current) return;
    isPointerOverRef.current = true;

    handleUserMention(e);
  }, []);

  const userMobilePointerUpHandler = useCallback((e: Event) => {
    userMentionMobilePointerUpRef.current = setTimeout(() => {
      handleUserMention(e);
    }, 0);
  }, []);

  const channelEventHandler = useCallback((e: Event) => {
    if (!channelRedirectTimeoutRef.current) {
      channelRedirectTimeoutRef.current = setTimeout(async () => {
        const el: HTMLElement | null = e?.target as any;
        const channelSlug = el?.dataset.slug;
        const communityId = el?.dataset.communityId;
        if (!communityId || !channelSlug) {
          console.error(
            `No communityId or channelSlug: ${communityId}, ${channelSlug}`
          );
          return;
        }

        const community = await communityStore.getCommunity(communityId);
        const communitySlug = community?.serverData.slug;

        if (!communitySlug) {
          console.error(`No communitySlug: ${communitySlug}`);
          return;
        }

        const channelPath = paths.getChannelPath(communitySlug, channelSlug);

        router.push(channelPath);
        channelRedirectTimeoutRef.current = null;
      }, 0);
    }
  }, []);

  return (
    <>
      <NodeEventPlugin
        nodeType={MentionUserNode as any}
        eventType={'pointerover'}
        eventListener={userEventHandler}
      />
      {isMobile && (
        <NodeEventPlugin
          nodeType={MentionUserNode as any}
          eventType={'pointerup'}
          eventListener={userMobilePointerUpHandler}
        />
      )}

      <NodeEventPlugin
        nodeType={MentionUserNode as any}
        eventType={'pointerout'}
        eventListener={() => {
          isPointerOverRef.current = false;
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }
        }}
      />
      <NodeEventPlugin
        nodeType={MentionChannelNode as any}
        eventType={'pointerup'}
        eventListener={channelEventHandler}
      />
    </>
  );
}

export const MessageEditorNodeEventPlugin = observer(
  _MessageEditorNodeEventPlugin
);
