import { classNames } from '@foundationPathAlias/utilities';
import { useTranslation } from 'react-i18next';
type Props = {
  isFirstUnread: boolean;
};
export function MessageUnreadSeparator(props: Props) {
  const { isFirstUnread } = props;

  const { t } = useTranslation('common');
  return (
    <div className="absolute -top-[22px] flex w-full items-center justify-end">
      <hr
        className={classNames(
          'h-[1px] w-full border-transparent',
          isFirstUnread ? 'bg-element-error-error' : 'bg-transparent'
        )}
      />
      <span
        className={classNames(
          'relative z-1 rounded-[3px] bg-element-error-error px-[8px] py-[2px] text-sm14RR text-utilities-white',
          isFirstUnread ? 'block' : 'hidden'
        )}
      >
        {t('new')}
      </span>
    </div>
  );
}
