import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useMemo } from 'react';

export function useIsAuthorTheSameUser(authorId: string | void | null) {
  const user = useInjection(IOC_TOKENS.userStore);
  const serverUserId = user.me?.serverData?.id;
  return useMemo(() => {
    return serverUserId === authorId;
  }, [serverUserId, authorId]);
}
