'use client';

import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';

import {
  BackButton,
  EditBtnTooltip,
  MenuButton,
  OutlineButton,
  RulesSVG,
} from '@foundationPathAlias/main';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenIds as CapScreenIds } from '@mainApp/src/modules/customer-admin-panel/screens/rules/constants';

import { BaseHeader } from '@mainApp/src/modules/channel/common/BaseHeader';
import { useScreenRules } from '../ScreenRules.context';
import { ScreenIds } from '../constants';

const ChannelHeaderLoginBtn = dynamic(
  () => import('@mainApp/src/modules/channel/common/ChannelHeaderAuthBtn'),
  {
    ssr: false,
  }
);

export const _RuleHeader = () => {
  const { t } = useTranslation(['channel']);

  const {
    systemStore,
    channelStore,
    capStore,
    dimensionsStore: { isMobile },
    communityStore,
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.channelStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.capStore,
    IOC_TOKENS.communityStore,
  ]);

  const { handleBack, currentItemId, currentPlatformRule, currentRuleDetails } =
    useScreenRules();

  const isLoading = channelStore.activeChannel.loading;

  const isUserAdmin = communityStore.activeCommunity.data?.isUserAdmin;

  const isOnInitialScreen = currentItemId === ScreenIds.INITIAL;

  const showMenuButton = isMobile && isOnInitialScreen;

  const handleEditRule = () => {
    capStore.setIsShow(true);
    capStore.setActiveScreenId(CapScreenIds.INITIAL);
  };

  let rightContent = null;

  if (isUserAdmin && currentItemId === ScreenIds.RULE_DETAILS) {
    rightContent = isMobile ? (
      <EditBtnTooltip
        onClick={handleEditRule}
        tooltipContent={t<string>('rules.editRule')}
        className="themed-text-secondary"
      />
    ) : (
      <OutlineButton
        cn="border-element-subtle dark:border-element-subtle-dark px-[20px] py-[8px] h-[40px] text-sm14SB"
        onClick={handleEditRule}
      >
        {t('rules.editRule')}
      </OutlineButton>
    );
  }

  let title = null;

  if (!isOnInitialScreen) {
    title =
      currentItemId === ScreenIds.SUB_RULES
        ? currentPlatformRule?.title
        : currentRuleDetails?.title;
  }

  const titleEl = title ? (
    <span className="themed-text text-body16SB md:text-subheadingSB">
      {title}
    </span>
  ) : null;

  const leftContent = showMenuButton ? (
    <MenuButton
      onClick={() => {
        systemStore.setIsSidebarOpened(true);
      }}
      className="themed-text"
    />
  ) : (
    !isOnInitialScreen && (
      <BackButton className="themed-text-secondary" onClick={handleBack} />
    )
  );

  const mainContent = (
    <div className="flex flex-1 items-center justify-center gap-[8px]">
      {isOnInitialScreen ? (
        <>
          <RulesSVG className="text-text-secondary" />

          <span className="text-subheading text-text-primary">
            {t('rules.title')}
          </span>
        </>
      ) : (
        titleEl
      )}
    </div>
  );

  const finalRightContent = (
    <div className="flex items-center justify-end self-center justify-self-end">
      {rightContent}

      <ChannelHeaderLoginBtn />
    </div>
  );

  return (
    <BaseHeader
      isLoading={isLoading}
      leftContent={leftContent}
      mainContent={mainContent}
      rightContent={finalRightContent}
    />
  );
};

export const RuleHeader = observer(_RuleHeader);
