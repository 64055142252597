// Must be used as a base for all the headers in the channel module. Currently used for the ChannelHeader and RuleHeader components.
import { ChannelTitleLoader } from '@mainApp/src/components/loaders';

type Props = {
  isLoading: boolean;
  leftContent: React.ReactNode;
  mainContent: React.ReactNode;
  rightContent: React.ReactNode;
};
export function BaseHeader(props: Props) {
  const { isLoading, leftContent, mainContent, rightContent } = props;

  const content = isLoading ? (
    <ChannelTitleLoader />
  ) : (
    <div className="flex h-full w-full items-center justify-center space-x-[6px]">
      <div className="absolute left-0 pl-[16px] md:pl-[20px]">
        {leftContent}
      </div>
      <div className="flex flex-1 justify-center">{mainContent}</div>

      <div className="absolute right-0 pr-[16px] md:pr-[20px]">
        {rightContent}
      </div>
    </div>
  );

  return (
    <div
      className="relative flex h-[44px] items-center justify-center space-x-[6px] border-b border-b-element-subtle px-[16px] 
          py-[7px] dark:border-b-element-subtle-dark md:h-[54px]
          "
    >
      {content}
    </div>
  );
}
