import debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import { observer } from 'mobx-react-lite';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';

import { classNames } from '@foundationPathAlias/utilities';

import { MessagesListLoader } from '@mainApp/src/components/loaders';

import { GlobalEvents } from '@mainApp/src/events';
import { useVirtualizer } from '@mainApp/src/forked-thirdparty';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { ReplyParentEvtData } from '@mainApp/src/stores/Message.store.types';

import { HistoryLoadingPanel } from './common';
import { ChannelCtx } from './common/channelContext';
import { Message } from './message/Message';

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function _ChannelMessagesList() {
  const {
    dimensionsStore: { isMobile },
    messageStore,
    eventBus: globalEventBus,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.eventBus,
    IOC_TOKENS.messageStore,
  ]);
  const [state, setState] = useState({
    enableOvfAnchNone: true,
  });

  const prevMessageModelRef = useRef<MessageModel | null>(null);

  const channelCtx = useContext(ChannelCtx);

  const virtualizerRef = useRef<any>(null);

  const isScrolledUpAfterBeingAtBottomRef = useRef(false);
  const wasInitialAutoScrollRef = useRef<any>(false);
  const currentActiveChannelIdRef = useRef<string | null>(null);

  // save prev messages count to detect initial render and autoscroll to bottom
  const prevMsgCountRef = useRef(0);

  const messagesArrayRef = useRef<MessageModel[]>([]);

  const contentScrollHandler = (e: Event) => {
    globalEventBus.emit(GlobalEvents.MESSAGE_LIST_SCROLL, e);
  };

  const throttledContentScrollHandlerRef = useRef(
    _throttle(contentScrollHandler, 17)
  );

  const activeChannelModel = messageStore.channelStore.activeChannel;
  const activeChannelId = activeChannelModel.data?.serverData?.id;
  useEffect(() => {
    if (!activeChannelId) return;

    if (currentActiveChannelIdRef.current !== activeChannelId) {
      currentActiveChannelIdRef.current = activeChannelId;
      wasInitialAutoScrollRef.current = false;
    }
  }, [activeChannelId]);

  let proceedMiddleVisibleMessage: (target: HTMLElement) => void;

  const { ref: olderMessagesInViewRef, inView: olderMessagesInView } =
    useInView();

  const scrollToReplyParent = useCallback(
    (data: ReplyParentEvtData | undefined) => {
      if (!data) {
        throw new Error('There is no passed data');
      }
      const virtualizer = virtualizerRef.current;
      if (!virtualizer) {
        throw new Error('Virtualizer is not defined');
      }
      const { replyParentMsgModel, replyParentIndex, isFetched } = data;

      // TODO: think how to do it correctly. It's to do actions when the scroller will be proceeded
      setTimeout(() => {
        messageStore.isJumpToRunning = false;

        if (isFetched) {
          virtualizer.scrollToIndex(replyParentIndex, {
            align: 'start',
            // after scroll the parent reply message should have some messages above from the top thus adding some padding to show a few messages above
            withPadding: true,
          });
        } else {
          const visibleIndexes = virtualizer.getIndexes();

          if (visibleIndexes.includes(replyParentIndex)) {
            // the reply parent is visible. Should just highlight it

            if (replyParentMsgModel.isParentAndScrollTo) {
              // resetting it and set again to trigger animation again
              replyParentMsgModel.setIsParentAndScrollTo(false);
            }
          } else {
            // the reply parent is not visible
            virtualizer.scrollToIndex(replyParentIndex, {
              align: 'start',
              // after scroll the parent reply message should have some messages above from the top thus adding some padding to show a few messages above
              withPadding: true,
            });
          }

          // should trigger the highlight of the Message when it had been scrolled to the user
          setTimeout(() => {
            replyParentMsgModel.setIsParentAndScrollTo(true);
          }, 0);
        }
      }, 0);
    },
    [virtualizerRef.current]
  );

  function handleScrollToLatest(messagesCount?: number) {
    // as it happens on event before the virtualizer final render should update count manually as it could be different that it was before this event thus the virtualizer could try to get item with unexisting item
    if (messagesCount) {
      virtualizer.setOptions({
        ...virtualizer.options,
        count: messagesCount,
      });
    }
    virtualizer.scrollToBottom();
    messageStore.isJumpToLatest = false;
  }

  useEffect(() => {
    globalEventBus.on(GlobalEvents.GO_TO_REPLY_PARENT, scrollToReplyParent);
    globalEventBus.on(GlobalEvents.GO_TO_LATEST, handleScrollToLatest);

    return () => {
      globalEventBus.removeListener(
        GlobalEvents.GO_TO_REPLY_PARENT,
        scrollToReplyParent
      );
      globalEventBus.removeListener(
        GlobalEvents.GO_TO_LATEST,
        handleScrollToLatest
      );
    };
  }, []);

  // predicted height of a message
  const itemSize = 120;
  // handling backward navigation
  useEffect(() => {
    if (olderMessagesInView) {
      // should fetch only when there was initial scroll
      if (wasInitialAutoScrollRef.current) {
        // should preserve scroll on new messages loading

        if (messageStore.messages.meta.hasPreviousPage) {
          prevMessageModelRef.current =
            messageStore.getTopMessage() as MessageModel;

          // saving lastMsgIndexBeforePagination
          messageStore.fetchMoreMessages();
        }
      }
    }
  }, [olderMessagesInView]);

  // handling forward navigation
  async function handleForwardMessage() {
    if (messageStore.firstUnreadMessageModel) {
      const lastItemFromList = await messageStore.messages.data[
        messageStore.messages.data.length - 1
      ];

      messageStore.markMessageAsRead(lastItemFromList);
    }

    messageStore.fetchMoreUnreadMessages();
  }

  useEffect(() => {
    const isReachedBottom = virtualizerRef?.current?.isReachedBottomThreshold;
    if (isReachedBottom && wasInitialAutoScrollRef.current) {
      if (messageStore.messages.meta?.hasNextPage) {
        // Test: try to disable it because of the reply functionality
        // going to the middle of list, scrolling to the bottom so
        // the overflow anchor will be visible and the scrollbar will be
        // pinned to the bottom but it shouldn't because when the newest messages will be loaded it should stay at the same place like it was before the `forward` pagination
        setState({
          enableOvfAnchNone: false,
        });

        prevMessageModelRef.current =
          messageStore.getLastMessage() as MessageModel;

        handleForwardMessage();
      }
    }
  }, [virtualizerRef?.current?.isReachedBottomThreshold]);

  // eslint-disable-next-line prefer-const
  proceedMiddleVisibleMessage = (target: HTMLElement) => {
    const virtualizer = virtualizerRef.current;
    const visibleIndexes = virtualizer.getIndexes();

    // the top border of the visible viewport rect regarding scrollHeight when the scrollbar at the bottom
    const visibleRectTop = target.scrollHeight - target.clientHeight;

    // the top border of the next visible viewport rect regarding scroll height when the user scrolls to top from the bottom
    const nextVisibleRectTop = visibleRectTop - target.clientHeight;

    const isScrolledBottomViewport = target.scrollTop < nextVisibleRectTop;

    const ln = visibleIndexes.length - 1;
    const middleVisibleIndex = visibleIndexes[ln > 1 ? Math.round(ln / 2) : ln];

    const msgModel = messagesArrayRef.current[middleVisibleIndex];

    if (msgModel) {
      // if left the bottom viewport - can start to save the position because if the user was at the bottom viewport previously - there is no reason to save the position
      if (isScrolledBottomViewport) {
        messageStore.visibleMessageData = {
          visibleMessageCursor: msgModel.cursor,
          visibleMessageId: msgModel.serverData.id,
        };
      } else {
        // clear previous values if the user was scrolled away and moved back to the bottom
        messageStore.visibleMessageData = {
          visibleMessageCursor: null,
          visibleMessageId: null,
        };
      }
    }
  };

  const debouncedProceedMiddleVisibleMsg = useCallback(
    debounce(proceedMiddleVisibleMessage, 500),
    []
  );

  function virtualScrollHandler(e: Event) {
    const virtualizer = virtualizerRef.current;
    throttledContentScrollHandlerRef.current(e);

    debouncedProceedMiddleVisibleMsg(e.target as HTMLElement);

    if (!virtualizer.isScrollBarAtBottom) {
      setState({
        enableOvfAnchNone: false,
      });
    } else {
      setState({
        enableOvfAnchNone: true,
      });

      // should check if the last visible cursor is in the viewport
      if (messageStore.showJumpToLatestButton) {
        const virtualIndexes = virtualizer.getIndexes();
        const lastVirtualIndex = virtualIndexes[virtualIndexes.length - 1];
        const lastIVirtualItem = messageStore?.messages?.data[lastVirtualIndex];

        if (lastIVirtualItem.id === messageStore.lastMessageId) {
          messageStore.setShowJumpToLatestButton(false);
        }
      }

      // should mark all messages as read when reach bottom is exist unread
      if (
        !messageStore.messages.meta.hasNextPage &&
        messageStore.firstUnreadMessageModel
      ) {
        messageStore.markMessagesAllAsRead(
          (messageStore.getLastMessage() as MessageModel).serverData.createdAt
        );
        // there shouldn't be unread messages anymore
        messageStore.setFirstUnreadMessageModel(null);
      }
    }
  }

  const rows = useMemo(() => {
    return messageStore?.messages?.data ?? [];
  }, [messageStore?.messages?.data]);

  isScrolledUpAfterBeingAtBottomRef.current =
    virtualizerRef.current?.isScrollBarAtBottom;

  const count = rows.length;
  // controls the initial scroll behavior: scroll to bottom or to the beginning of the new message
  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.addEventListener('scroll', virtualScrollHandler);
    }

    const virtualizer = virtualizerRef.current;

    if (!virtualizer) {
      return;
    }
    // shouldn't automatically scroll at bottom if we have the unread messaes
    if (virtualizer.isScrollContentExists) {
      if (!virtualizer.isScrollBarAtBottom) {
        if (!wasInitialAutoScrollRef.current) {
          // handling unread messages
          if (
            messageStore.firstUnreadMessageModel &&
            // shouldn't go to the read/unread messages intersection if jump to latest
            !messageStore.isJumpToLatest
          ) {
            const firstUnreadMsgOrderIndex =
              messageStore.firstUnreadMessageModel.orderIndex;

            virtualizer.scrollToIndex(firstUnreadMsgOrderIndex, {
              align: 'center',
            });
            wasInitialAutoScrollRef.current = true;
            prevMsgCountRef.current = count;
            return;
          }
          virtualizer.scrollToBottom();
          wasInitialAutoScrollRef.current = true;
        }

        prevMsgCountRef.current = count;
        return;
      }
    }

    return () => {
      if (parentRef.current) {
        parentRef.current.removeEventListener('scroll', virtualScrollHandler);
      }
    };
  }, [count]);

  // handles scroll on the pagination, subscription event, jump to latest or previously visited behavior
  useLayoutEffect(() => {
    // handle the previously visited item and scroll to this item
    if (messageStore.previouslyVisitedIndex) {
      virtualizer.scrollToIndex(messageStore.previouslyVisitedIndex, {
        align: 'center',
      });

      // reset it because it's already processed
      messageStore.previouslyVisitedIndex = null;

      // updating this value because it's needed for the further use
      prevMsgCountRef.current = count;

      // should make it true to prevent auto scroll to bottom at the init
      wasInitialAutoScrollRef.current = true;
      return;
    }

    if (messageStore.isJumpToLatest) {
      messageStore.isJumpToLatest = false;
      virtualizer.scrollToBottom();
      prevMsgCountRef.current = count;
      return;
    }

    // new message/s added
    if (count > prevMsgCountRef.current && wasInitialAutoScrollRef.current) {
      const delta = count - prevMsgCountRef.current;
      // if there is a subscription message adding so delta will be like 1 or 2 in rare cases or vlist re-render (optimistic and the real one), but it should react only on the new infinity scrolling batch loading.

      if (delta === 1) {
        /**
         * scrollbar was at the bottom, new optimistic message had been added, should scroll to bottom to maintain positio. Helps the anchored view
         */
        if (isScrolledUpAfterBeingAtBottomRef.current) {
          virtualizer.scrollToBottom();
          isScrolledUpAfterBeingAtBottomRef.current = false;
        }
        prevMsgCountRef.current = count;
        return;
      }

      if (messageStore.paginationDirection === 'backward') {
        const previousFirstMessageCurrentIndex =
          // trying to use the real messages delta
          0 + delta;

        virtualizer.scrollToIndex(previousFirstMessageCurrentIndex, {
          align: 'start',
        });
      } else {
        if (prevMessageModelRef.current) {
          virtualizer.scrollToIndex(
            prevMessageModelRef.current?.orderIndex as number,
            {
              align: 'end',
            }
          );
        } else {
          // use case when there was fetched manually a bunch of new messages and need to just scroll to the last message
          virtualizer.scrollToBottom();
        }
      }

      prevMsgCountRef.current = count;

      return;
    }

    // message/s removed
    if (count < prevMsgCountRef.current && wasInitialAutoScrollRef.current) {
      const delta = count - prevMsgCountRef.current;
      if (Math.abs(delta) === 1) {
        // update the current count. Necessary for the further calculations and scroll pin to the bottom when it's on the bottom -> message removed -> created a new message (important for Safari)
        prevMsgCountRef.current = count;
        return;
      }
    }
  }, [count]);

  const parentRef = useRef<HTMLDivElement | null>(null);

  const virtualizer = useVirtualizer({
    getScrollElement: () => parentRef.current,
    count,
    estimateSize: () => itemSize,
    getItemKey: useCallback(
      (index: number) => {
        return rows[index].serverData.id;
      },
      [rows]
    ),
    overscan: 5,
    scrollMargin: 50,
    // substracts of scroll align start and allows to adjust scrollToIndex {align: start}
    scrollPaddingStart: 150,
  });

  useIsomorphicLayoutEffect(() => {
    virtualizerRef.current = virtualizer;
    channelCtx.setVirtualizer(virtualizer);
  }, [virtualizer]);

  const items = virtualizer.getVirtualItems();

  const [paddingTop, paddingBottom] =
    items.length > 0
      ? [
          Math.max(0, items[0].start - virtualizer.options.scrollMargin),
          Math.max(0, virtualizer.getTotalSize() - items[items.length - 1].end),
        ]
      : [0, 0];

  const messages = messageStore?.messages;

  messagesArrayRef.current = messages?.data;

  return (
    <>
      <div className="relative flex max-w-[100%] flex-1 flex-col overflow-hidden">
        {/* <MessagesListLoader /> */}
        {messageStore.isInitialMessagesLoading ? (
          <MessagesListLoader isMobile={isMobile} />
        ) : messagesArrayRef.current?.length ? (
          <div
            ref={parentRef}
            className={classNames(
              'list-scroller scrollbar-track-rounded-full scrollbar-thumb-rounded-full relative mt-[2px] w-full flex-1 overflow-auto  pt-[50px] scrollbar-thin scrollbar-track-element-subtle scrollbar-thumb-color-4 ',
              state.enableOvfAnchNone && 'ovf-anch-none'
            )}
          >
            {virtualizer.isScrollContentExists && (
              <HistoryLoadingPanel
                ref={olderMessagesInViewRef}
                messages={messages}
                messageStore={messageStore}
              />
            )}

            <div
              className="-mb-[1px] flex min-h-full max-w-[100%] flex-1 flex-col justify-end"
              style={{
                paddingTop,
                paddingBottom,
              }}
            >
              {items.map((item) => {
                const index = item.index;
                const messageModel = rows[index];
                if (!messageModel) {
                  throw new Error(`No Message Model for Index: ${index}`);
                }

                const isFirstUnread =
                  messageModel.id === messageStore.firstUnreadMessageModel?.id;

                return (
                  <div
                    key={messageModel?.serverData.id}
                    data-index={item.index}
                    data-reverse-index={index}
                    className="w-full"
                    ref={virtualizer.measureElement}
                  >
                    <Message
                      isFirstUnread={isFirstUnread}
                      messageModel={messageModel}
                      onGoToReplyParent={messageStore.goToReplyParent}
                    />
                  </div>
                );
              })}
            </div>

            <div id="scroll-anchor"></div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export const ChannelMessagesList = observer(_ChannelMessagesList);
