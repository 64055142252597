// TODO type;
// @ts-nocheck
import { TextNode } from 'lexical';

function convertElement(domNode) {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createMentionAdminsNode(textContent);
    return {
      node,
    };
  }

  return null;
}

const TYPE = 'mentionAdmin';

export class MentionAdminNode extends TextNode {
  __name;
  __data;

  static getType() {
    return TYPE;
  }

  static clone(node) {
    return new MentionAdminNode(
      node.__data,
      node.__name,
      node.__text,
      node.__key
    );
  }
  static importJSON(serializedNode) {
    const node = $createMentionAdminNode(
      serializedNode.data,
      serializedNode.name
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(data, name, text?, key?) {
    super(text ?? name, key);
    this.__data = data;
    this.__name = name;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      name: this.__name,
      data: this.__data,
      type: TYPE,
      version: 1,
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.className = 'mention-admin mention';
    // dom.dataset.id = this.__data.id;
    return dom;
  }

  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention-admin', 'true');
    // element.setAttribute('data-mention-admin-id', this.__data.id);
    element.textContent = this.__text;
    return { element };
  }

  isSegmented() {
    return false;
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute('data-lexical-mention-admin')) {
          return null;
        }
        return {
          conversion: convertElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }

  isToken() {
    return true;
  }
}

export function $createMentionAdminNode(data, name) {
  const node = new MentionAdminNode(data, name);
  node.setMode('segmented').toggleDirectionless();
  return node;
}

export function $isMentionAdminNode(node) {
  return node instanceof MentionAdminNode;
}
