import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { loaderColors } from './loaderColors';

export type Props = {
  authorLineWidth: number;
  messageLineWidth: number;
  thirdLineWidth: number;
} & IContentLoaderProps;

export const MessageLoader = (props: Props) => {
  const {
    authorLineWidth = 230,
    messageLineWidth = 280,
    thirdLineWidth,
    ...otherProps
  } = props;

  const mainH = thirdLineWidth ? 98 : 84;
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={mainH}
      backgroundColor={loaderColors.backgroundColor}
      foregroundColor={loaderColors.foregroundColor}
      {...otherProps}
    >
      <rect x="1" y="8" rx="100" ry="100" width="32" height="32"></rect>
      <rect
        x="42"
        y="6"
        rx="2"
        ry="2"
        width={authorLineWidth}
        height="24"
      ></rect>
      <rect
        x="42"
        y="36"
        rx="2"
        ry="2"
        width={messageLineWidth}
        height="24"
      ></rect>
      {thirdLineWidth && (
        <rect
          x="42"
          y="66"
          rx="2"
          ry="2"
          width={thirdLineWidth}
          height="24"
        ></rect>
      )}
      {/* <rect x="1" y="8" rx="100" ry="100" width="32" height="32"></rect>
      <rect x="42" y="6" rx="2" ry="2" width="230" height="24"></rect>
      <rect x="42" y="36" rx="2" ry="2" width="280" height="24"></rect>
      <rect x="42" y="66" rx="2" ry="2" width="280" height="24"></rect> */}
    </ContentLoader>
  );
};
