import {
  Popover,
  PopoverContent,
  PopoverFactory,
} from '@10x/foundation/src/components/popover';

import { FloatingOverlay } from '@floating-ui/react';

import { observer } from 'mobx-react-lite';

import { messageEditorStore } from '@mainApp/src/modules/channel/editor-area/MessageEditor.store';

import { UserModel } from '@mainApp/src/stores/User.model';
import { PopoverTypeEnum } from '../types';
import { MentionsPopoverUserContentWithLoader } from './mentions-content';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { AttachmentsUploadMenu } from '@mainApp/src/modules/channel/attachments';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';
import { useEffect } from 'react';

const contentRegistry = {
  [PopoverTypeEnum.MENTION_USER]: MentionsPopoverUserContentWithLoader,
  [PopoverTypeEnum.CHANNEL]: (_props: any) => null,
  [PopoverTypeEnum.MENTION_ADMIN]: (_props: any) => null,
  [PopoverTypeEnum.ATTACHMENTS]: AttachmentsUploadMenu,
};

export function _ChannelPopover() {
  const { popoverData } = messageEditorStore;
  const { show, data, loading, PopoverProps, type, triggerEl } = popoverData;

  const {
    interactiveStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const Content = contentRegistry[type];

  useEffect(() => {
    if (isMobile) {
      if (show) {
        interactiveStore.setContent(
          <Content
            isLoading={loading}
            //  TODO: for now it supports only UserModel
            data={data as unknown as UserModel}
          />
        );
        interactiveStore.setActiveContentType(
          InteractiveContentTypesEnum.OTHER,
          true
        );
        interactiveStore.setInteractiveElementOpen(true);
      }
    } else {
      if (show) {
        interactiveStore.setContent(null);
        interactiveStore.setInteractiveElementOpen(false);
      }
    }
  }, [isMobile, data, loading]);

  useEffect(() => {
    if (isMobile) {
      if (!interactiveStore.isBottomSheetOpened && show) {
        messageEditorStore.hidePopover();
      }
    }
  }, [isMobile, show, interactiveStore.isBottomSheetOpened]);

  return isMobile ? null : (
    <Popover className="border-none focus:outline-none" {...PopoverProps}>
      {show && (
        // @ts-ignore
        <PopoverContent>
          <Content
            isLoading={loading}
            //  TODO: for now it supports only UserModel
            data={data as unknown as UserModel}
          />
        </PopoverContent>
      )}

      <PopoverFactory
        triggerEl={triggerEl}
        isOpen={show}
        onClose={messageEditorStore.hidePopover}
      />

      {show && <FloatingOverlay />}
    </Popover>
  );
}

export const ChannelPopover = observer(_ChannelPopover);
