import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { loaderColors } from './loaderColors';

export const ChannelTitleLoader = (props: IContentLoaderProps) => (
  <ContentLoader
    uniqueKey="ChannelTitleLoader"
    speed={2}
    width={260}
    height={32}
    viewBox="0 0 200 32"
    backgroundColor={loaderColors.backgroundColor}
    foregroundColor={loaderColors.foregroundColor}
    {...props}
  >
    <rect x="20" y="4" rx="0" ry="0" width="20" height="20" />
    <rect x="54" y="0" rx="0" ry="0" width="140" height="28" />
  </ContentLoader>
);
