import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import { loaderColors } from './loaderColors';

export const CommunityLogoLoader = (props: IContentLoaderProps) => (
  <ContentLoader
    uniqueKey="CommunityLogoLoader"
    speed={2}
    width={40}
    height={40}
    viewBox="0 0 40 40"
    backgroundColor={loaderColors.backgroundColor}
    foregroundColor={loaderColors.foregroundColor}
    {...props}
  >
    <rect x="0" y="0" rx="5" ry="5" width="40" height="40" />
  </ContentLoader>
);
