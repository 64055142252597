import {
  Tooltip,
  TooltipPropsType,
} from '@foundationPathAlias/components/tooltip';

import {
  SquareIconButtonBase,
  SquareIconButtonBasePropsType,
} from './SquareIconButtonBase';

export type SquareIconButtonBaseTooltipPropsType = {
  tooltipContent: string;
  darkMode?: boolean;
  TolltipProps?: Partial<TooltipPropsType>;
} & SquareIconButtonBasePropsType;

export function SquareIconButtonBaseTooltip(
  props: SquareIconButtonBaseTooltipPropsType
) {
  const {
    tooltipContent,
    darkMode,
    children,
    onClick,
    onPointerUp,
    className = '',
    disabled,
    TolltipProps = {},
  } = props;

  return (
    <Tooltip
      darkMode={Boolean(darkMode)}
      triggerEl={
        <SquareIconButtonBase
          iconWrapperCn="h-[14px] w-[14px]"
          className={className}
          onClick={onClick}
          onPointerUp={onPointerUp}
          disabled={disabled}
        >
          {children}
        </SquareIconButtonBase>
      }
      {...TolltipProps}
    >
      {tooltipContent}
    </Tooltip>
  );
}
