// import { MessagesEventsEnum } from '@mainApp/src/events';
import {
  CommonMenuItemButton,
  ContextMenu,
} from '@foundationPathAlias/components';

import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { MessageMenuButton } from './MessageMenuButton';

import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { useRef, useState } from 'react';

type State = {
  anchorEl: HTMLDivElement | null;
  isShow: boolean;
};

type Props = {
  messageModel: MessageModel;
  onClose: () => void;
};

export function MessageMenuMore(props: Props) {
  const { onClose, messageModel } = props;
  const { t } = useTranslation('common');
  const [showMoreState, setShowMoreState] = useState<State>({
    anchorEl: null,
    isShow: false,
  });

  const messageStore = useInjection(IOC_TOKENS.messageStore);

  const showMoreAnchorEl = useRef<HTMLDivElement | null>(null);

  if (!messageModel.serverData.read) return null;

  return (
    <li>
      <MessageMenuButton
        label={t('more')}
        Icon={EllipsisVerticalIcon}
        getRef={(el) => {
          showMoreAnchorEl.current = el;
        }}
        onClick={() => {
          if (!showMoreAnchorEl.current) {
            throw new Error('There is no anchor element');
          }
          setShowMoreState({
            anchorEl: showMoreAnchorEl.current,
            isShow: true,
          });
          // deleteMessageModalStoreLocal.show(messageModel);
          // onClose();
        }}
      />
      <ContextMenu
        anchorEl={showMoreState.anchorEl}
        withoutAnchorAsTrigger
        isShow={showMoreState.isShow}
        placement="left-start"
        onClose={() => {
          onClose();
        }}
      >
        <ul className="rounded-[5px] border-[1px] py-[8px] shadow-shadow-menu-dekstop">
          {/* TODO: it must be here but BE currently doesn't support it */}
          {/* <CommonMenuItemButton
            label="Copy message link"
            icon={<LinkIcon />}
            onClick={() => {
              alert('Click on link');
              onClose();
            }}
          /> */}
          <CommonMenuItemButton
            label={t('markAsUnread')}
            icon={<EyeSlashIcon />}
            onClick={() => {
              messageStore.markMessageAsUnread(messageModel);
              onClose();
            }}
          />
        </ul>
      </ContextMenu>
    </li>
  );
}
