import { UserModel } from '@mainApp/src/stores/User.model';

import {
  MentionUserPopoverLoader,
  withLoading,
} from '@mainApp/src/components/loaders';

import { LinkIcon } from '@heroicons/react/20/solid';

import { withMentionsPopoverContentHOC } from './withMentionsPopoverContentHOC';

export type Props = {
  data: UserModel;
};
export function MentionsPopoverUserContent(props: Props) {
  const { data: userModel } = props;
  if (!userModel || !userModel.serverData) return null;

  const { firstName, lastName, username, numericId, bio, website } =
    userModel.serverData;
  return (
    <>
      <figure className="mb-[20px] flex flex-col items-center">
        <img
          className="mb-[16px] h-[120px] w-[120px] rounded-full object-cover"
          src={userModel.getAvatar()}
          alt="User avatar"
        />

        <figcaption className="flex flex-col items-center text-body16SB">
          <b className="themed-text text-subheadingSB">
            {firstName} {lastName}
          </b>
          <p className="body16SB themed-text-secondary">
            @{username} {numericId && `#${numericId}`}
          </p>
        </figcaption>
      </figure>
      <div className="mb-[20px]">
        <p className="themed-text-secondary text-body16R">{bio}</p>
      </div>

      {website && (
        <a
          className="hover-el flex text-body16SB text-primary-100 outline-none"
          href={website || '#'}
          target="_blank"
        >
          <LinkIcon className="text-primary-100" width={18} />

          <span className="ml-[4px] block text-body16SB text-primary-100 dark:text-primary-100-dark">
            {website}
          </span>
        </a>
      )}
    </>
  );
}

export const MentionsPopoverUserContentWithLoader =
  withMentionsPopoverContentHOC(
    withLoading(MentionsPopoverUserContent, MentionUserPopoverLoader, {
      // to avoid parent component padding shift because svg has full w/h
      className: 'absolute left-0 top-0',
    })
  );
