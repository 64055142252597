import { User } from '@10x/foundation/types';
import { Avatar } from '@foundationPathAlias/components';
import {
  UserProfileCard,
  UserProfileTooltipWrapper,
} from '@mainApp/src/components/common/user-profile-card';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useIsAuthorTheSameUser } from './hooks';

export type Props = {
  avatarUrl?: string;
  author?: Pick<
    User,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'username'
    | 'bio'
    | 'website'
    | 'imageUrls'
  > | null;
};

export function _UserAvatarWithPopover(props: Props) {
  const { avatarUrl, author } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isAuthorTheSameUser = useIsAuthorTheSameUser(author?.id);
  const handleCloseTooltip = () => {
    setTooltipOpen(false);
  };

  const {
    systemStore,
    dimensionsStore,
    interactiveStore,
    accountSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.accountSettingsStore,
  ]);

  const cardProfileContent = (
    <UserProfileCard
      onClose={handleCloseTooltip}
      hideAction={true}
      hideMenu={true}
      darkMode={systemStore.isDarkTheme}
      user={
        author
          ? {
              ...author,
              avatarUrl,
            }
          : undefined
      }
      onSettings={() => {
        accountSettingsStore.setShow(true);
      }}
      ownProfile={isAuthorTheSameUser}
    />
  );

  return dimensionsStore.isMobile ? (
    <button
      onClick={() => {
        interactiveStore.setContent(cardProfileContent);
        interactiveStore.setBottomSheet(true);
      }}
    >
      <Avatar width={32} height={32} src={avatarUrl} />
    </button>
  ) : (
    <UserProfileTooltipWrapper
      open={tooltipOpen}
      onClose={handleCloseTooltip}
      onOpen={() => setTooltipOpen(true)}
      triggerEl={
        <button>
          <Avatar width={32} height={32} src={avatarUrl} />
        </button>
      }
    >
      {cardProfileContent}
    </UserProfileTooltipWrapper>
  );
}

export const UserAvatarWithPopover = observer(_UserAvatarWithPopover);
