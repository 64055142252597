import { getRandomNumberInRange } from '@foundationPathAlias/utilities/common';

import { useEffect } from 'react';
import { MessageLoader } from './MessageLoader';

const cache = new Map();

function generateLoaderData() {
  const cW = document.documentElement.clientWidth || 0;
  const exactCw = cW - 98;

  const authorLineWidth = getRandomNumberInRange(
    110,
    380 < exactCw ? 380 : exactCw
  );
  const messageLineWidth = getRandomNumberInRange(
    180,
    460 < exactCw ? 460 : exactCw
  );
  const shouldShowThirdLine = Math.random() < 0.5;
  let thirdLineWidth = 0;
  if (shouldShowThirdLine) {
    thirdLineWidth = getRandomNumberInRange(120, 320 < exactCw ? 320 : exactCw);
  }

  return {
    authorLineWidth,
    messageLineWidth,
    thirdLineWidth,
  };
}

type Props = {
  isMobile: boolean;
};

export function MessagesListLoader(props: Props) {
  const { isMobile } = props;

  useEffect(() => {
    if (cache.size > 0) {
      cache.clear();
    }
  }, [isMobile]);
  return (
    <div className="absolute bottom-0 w-full pl-[19px]">
      {[...Array(12)].map((_, index) => {
        let loaderData = cache.get(index);
        if (!loaderData) {
          loaderData = generateLoaderData();
          cache.set(index, loaderData);
        }

        const { authorLineWidth, messageLineWidth, thirdLineWidth } =
          loaderData;

        return (
          <div className="my-[12px]" key={'MessagesListLoaderKey-' + index}>
            <MessageLoader
              authorLineWidth={authorLineWidth}
              messageLineWidth={messageLineWidth}
              thirdLineWidth={thirdLineWidth}
              uniqueKey={'MessageLoaderKey-' + index}
            />
          </div>
        );
      })}
    </div>
  );
}
