import { Emoji } from '@foundationPathAlias/forked-thirdparty/emoji-picker-react';

type Props = {
  emojiUnified: string;
  count: number;
  size?: number;
};

export const ReactionAndCount = (props: Props) => {
  const { emojiUnified, count, size } = props;
  return (
    <div className="flex flex-row items-center justify-center">
      <Emoji unified={emojiUnified} {...(size && { size: size })} />
      <div className="ml-1 text-sm14T text-text-primary">{count}</div>
    </div>
  );
};
