import {
  ContentReactionInput,
  ContentReactionPayload,
} from '@10x/foundation/types';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { MessageModel } from '@mainApp/src/stores/Message.model';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { UserReactionPopup, UserReactions } from '../user-reactions';
import { MessageReactionButton } from './MessageReactionButton';

export type MessageReactionBarPropsType = {
  messageModel: MessageModel;
};

const _MessageReactionBar = (props: MessageReactionBarPropsType) => {
  const { messageModel } = props;
  const {
    interactiveStore,
    messageStore,
    authStore: { logged },
  } = useMultipleInjection([
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.messageStore,
    IOC_TOKENS.authStore,
  ]);

  const [activePopupIndex, setActivePopupIndex] = useState<number | null>(null);

  const messageReactions = messageModel.serverData.reactions;

  const handleEmojiSelection = (emoji: ContentReactionPayload) => {
    setActivePopupIndex(null);
    const reaction: ContentReactionInput = {
      unified: emoji.unified,
      activeSkinTone: emoji.activeSkinTone,
      status: true,
    };
    messageStore.saveChannelMessageReaction(messageModel, reaction);
  };

  const handleUserReaction = () => {
    setActivePopupIndex(null);
    interactiveStore.setModal(true);
    interactiveStore.setContent(
      <div className="relative w-screen transform overflow-auto rounded-2xl bg-white text-left transition-all dark:bg-gray-800 md:h-[638px] md:max-w-[525px]">
        <UserReactions
          messageModel={messageModel}
          handleClose={() => interactiveStore.setModal(false)}
        />
      </div>
    );
  };

  const btmshtCnModifier = 'h-[80vh] bottom-[0px] pb-[0px]';

  const handleLongPress = useCallback(() => {
    interactiveStore.setContent(
      <UserReactions
        messageModel={messageModel}
        handleClose={() => {
          interactiveStore.setBottomSheet(false);
        }}
      />
    );
    interactiveStore.setBottomSheetCn(btmshtCnModifier);
    interactiveStore.setBottomSheet(true);
  }, [interactiveStore, messageModel]);

  useEffect(() => {
    const wereCnApplied = btmshtCnModifier === interactiveStore.bottomSheetCn;
    if (!interactiveStore.isBottomSheetOpened && wereCnApplied) {
      // need to reset the bottom sheet cn to default at the end
      interactiveStore.setBottomSheetCn('');
    }
  }, [interactiveStore.isBottomSheetOpened]);

  const nullifyFnIfGuest = (fn: any) => (logged ? fn : undefined);

  return (
    <div className="mt-1 flex flex-row items-center">
      {messageReactions.map(
        (reaction: ContentReactionPayload, index: number) => (
          <UserReactionPopup
            key={reaction.unified}
            index={index}
            disabled={!logged}
            reaction={reaction}
            handleEmojiSelection={nullifyFnIfGuest(handleEmojiSelection)}
            activePopupIndex={activePopupIndex}
            setActivePopupIndex={setActivePopupIndex}
            handleLongPress={nullifyFnIfGuest(handleLongPress)}
            handleUserReaction={handleUserReaction}
          />
        )
      )}

      {messageReactions.length > 0 && logged && (
        <div className="flex h-[28px] w-[38px] shrink cursor-pointer flex-row items-center justify-center rounded-[15px] border-[1px] border-transparent bg-surface-onBase-secondary px-3 hover:border-element-subtle hover:bg-hovered-selected dark:hover:border-element-subtle-dark dark:hover:bg-hovered-selected-dark">
          <MessageReactionButton
            messageModel={messageModel}
            offset={{ x: 1, y: 2 }}
            hideHoverStyles
            closeReactionsOnOneReaction
          />
        </div>
      )}
    </div>
  );
};

export const MessageReactionBar = observer(_MessageReactionBar);
