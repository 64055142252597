import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import { loaderColors } from './loaderColors';

export const TitleLoader = (props: IContentLoaderProps) => (
  <ContentLoader
    uniqueKey="CommunityTitleLoader"
    speed={2}
    width={120}
    height={24}
    viewBox="0 0 120 24"
    backgroundColor={loaderColors.backgroundColor}
    foregroundColor={loaderColors.foregroundColor}
    {...props}
  >
    <rect
      x="0"
      y="0"
      // rx="5" ry="5"
      width="120"
      height="24"
    />
  </ContentLoader>
);
