type Props = {
  title: string;
};

export function SuggestionsHeader(props: Props) {
  const { title } = props;
  return (
    <h5 className="mb-[10px] text-sm14T uppercase text-text-secondary opacity-70">
      {title}
    </h5>
  );
}
