import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChangesAlert,
  useAnimatedStack,
} from '@foundationPathAlias/components';
import { Modal as FNDModal } from '@foundationPathAlias/components/modal/Modal';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

export function _Profile() {
  const {
    profileStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.profileStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { t } = useTranslation(['common', 'channel']);
  const animatedStack = useAnimatedStack();

  useEffect(() => {
    profileStore.setAnimatedStack(animatedStack);
  }, [animatedStack]);

  const stackState = animatedStack.state;

  const stackVisibleItem = stackState.visibleItem;
  const stackPrevItem = stackState.prevItem;
  const stackNextItem = stackState.nextItem;

  return (
    <FNDModal
      rootInnerWrapperCn="w-full xs:justify-normal md:justify-center"
      contentWrapperCn="xs:p-0  xs:justify-normal xs:w-full md:w-screen z-8"
      contentCn={`flex overflow-hidden h-full xs:w-full md:max-w-[480px]  max-h-auto md:max-h-[640px] md:w-full md:mx-auto`}
      open={profileStore.active}
      onClose={profileStore.hide}
    >
      <div
        className={classNames(
          'relative flex h-full w-full flex-col',
          isMobile ? 'h-full' : 'overflow-hidden  rounded-[10px]'
        )}
      >
        <div className="flex flex-1">
          <div className="relative flex  flex-1 overflow-hidden">
            {stackPrevItem.Component && (
              <stackPrevItem.Component
                stackWrapperCn={classNames('!bg-transparent')}
                key={stackPrevItem.id}
              />
            )}
            {stackVisibleItem.Component && (
              <stackVisibleItem.Component
                stackWrapperCn="!bg-transparent"
                key={stackVisibleItem.id}
                startLeaving={stackState.startLeaving}
                onLeaveEnd={animatedStack.onLeavingAnimationEnd}
              />
            )}

            {stackNextItem.Component && (
              <stackNextItem.Component
                stackWrapperCn="!bg-transparent"
                isNext={true}
                startEntering={stackState.startEntering}
                key={stackNextItem.id}
                onEnterEnd={animatedStack.onEnterAnimationEnd}
              />
            )}
          </div>
        </div>
      </div>

      <ChangesAlert
        contentWrapperCn="rounded-[10px] overflow-hidden border-[1px] md:flex-col md:items-stretch"
        title={t('channel:modal.discardChangesTitle')}
        description={t('channel:modal.discardChangesDescription')}
        descriptionCn="text-left"
        show={profileStore.discardAlertIsActive}
        onFirstBtnClick={() => {
          profileStore.setDiscardAlertIsActive(false);
        }}
        onSecondBtnClick={() => {
          profileStore.dirtyScreenData?.resetAction();
        }}
        firstBtnText={t<string>('channel:modal.keepEditing')}
        secondBtnText={t<string>('channel:modal.closeAndDiscard')}
      />
    </FNDModal>
  );
}

export const Profile = observer(_Profile);
