export type Props = {
  tag: string;
  description: string;
  onClick: () => void;
};

export function SuggestionsGlobalMention({ tag, description, onClick }: Props) {
  return (
    <button
      className="suggestion-option w-full justify-between"
      onClick={onClick}
    >
      <span className="text-sm14T text-text-primary  dark:opacity-90">
        {tag}
      </span>
      <span className="text-sm13D text-text-secondary opacity-70">
        {description}
      </span>
    </button>
  );
}
