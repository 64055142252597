import { useEffect, useRef, useState } from 'react';

import { FloatingOverlay } from '@floating-ui/react';
import { Placement, Popover, PopoverContent, PopoverFactory } from '../popover';

import { classNames, useLongPress } from '@foundationPathAlias/utilities';

type Props = {
  anchorEl: HTMLElement | null;
  withoutAnchorAsTrigger?: boolean;
  isShow?: boolean;
  onClose?: () => void;
  onShow?: () => void;
  placement?: Placement;
  children?: React.ReactNode;
  popoverCn?: string;
};

export function ContextMenu(props: Props) {
  const {
    children,
    anchorEl,
    withoutAnchorAsTrigger,
    onShow,
    onClose,
    isShow: outerIsShow,
    popoverCn,
    placement = 'right-start',
  } = props;
  const withoutAnchorAsTriggerRef = useRef(withoutAnchorAsTrigger);
  const [isShow, setIsShow] = useState(false);

  let finalIsShow = isShow;
  let showAction: (() => void) | undefined = () => {
    setIsShow(true);
    onShow?.();
  };
  let closeAction = () => {
    alert('close action');
    setIsShow(false);
    onClose?.();
  };

  if (withoutAnchorAsTriggerRef.current) {
    if (outerIsShow === undefined || !onClose) {
      throw new Error(
        'If `acnchorAsTrigger` is true, `isShow`, `onShow`, and `onClose` must be provided'
      );
    }
    finalIsShow = outerIsShow;
    showAction = onShow;
    closeAction = onClose;
  }

  const longPressHandlers = useLongPress(longPessHandler as any);

  function contextMenuHandler(e: MouseEvent) {
    e.preventDefault();
    showAction?.();
  }

  function longPessHandler(e: TouchEvent) {
    e.preventDefault();
    showAction?.();
  }

  useEffect(() => {
    if (withoutAnchorAsTriggerRef.current || !anchorEl) return;

    anchorEl.addEventListener('contextmenu', contextMenuHandler);
    anchorEl.addEventListener('touchstart', longPressHandlers.onTouchStart);
    anchorEl.addEventListener('touchend', longPressHandlers.onTouchEnd);

    return () => {
      anchorEl.removeEventListener('contextmenu', contextMenuHandler);
      anchorEl.removeEventListener(
        'touchstart',
        longPressHandlers.onTouchStart
      );
      anchorEl.removeEventListener('touchend', longPressHandlers.onTouchEnd);
    };
  }, [anchorEl, withoutAnchorAsTriggerRef.current]);

  if (!anchorEl) return null;

  return (
    <div>
      <Popover
        className={classNames('border-none focus:outline-none', popoverCn)}
        placement={placement}
      >
        {
          // @ts-ignore
          <PopoverContent>{children}</PopoverContent>
        }

        <PopoverFactory
          triggerEl={anchorEl}
          isOpen={finalIsShow}
          onClose={closeAction}
        />
        {finalIsShow && <FloatingOverlay />}
      </Popover>
    </div>
  );
}
