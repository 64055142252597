// import { HashTagMessageSVG } from '@foundationPathAlias/components/svg';
import {
  ArrowUturnLeftIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';

import { observer } from 'mobx-react-lite';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { MessageModel } from '@mainApp/src/stores/Message.model';

import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { Separator } from '@mainApp/src/components/common';
import { useTranslation } from 'react-i18next';
import { deleteMessageModalStoreLocal } from '../delete-message-modal';
import { MessageMenuButton } from './MessageMenuButton';
import { MessageMenuMore } from './MessageMenuMore';
import { MessageReactionButton } from './MessageReactionButton';
import { ReactionsBar } from './ReactionsBar';

type Props = {
  messageModel: MessageModel;
  onClose: () => void;
  handleReactionClick?: () => void;
};

function _MessageMenu(props: Props) {
  const { onClose, messageModel, handleReactionClick } = props;

  const {
    messageStore,
    userStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.messageStore,
    IOC_TOKENS.userStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { t } = useTranslation('common');

  const isMyMessage =
    userStore.me?.serverData.id === messageModel.serverData.authorId;

  return (
    <div>
      {isMobile && (
        <ReactionsBar
          handleReactions={handleReactionClick}
          messageModel={messageModel}
        />
      )}
      <ul
        className={
          isMobile
            ? 'flex flex-col pb-[12px] [&>*]:px-[24px]'
            : 'absolute right-[21px] top-[-24px] z-1 flex w-auto whitespace-nowrap rounded-[5px] border-[1px] border-element-subtle bg-baseTheme-background-product px-[2px] py-[4px] before:pointer-events-none before:absolute before:inset-0 before:-z-[1] before:rounded-[5px] before:bg-surface-mapping-base-tertiary'
        }
      >
        {!isMobile && (
          <MessageReactionButton
            offset={{ x: -102, y: 8 }}
            messageModel={messageModel}
            closeMessageMenu={onClose}
          />
        )}
        <MessageMenuButton
          label={t('reply')}
          Icon={ArrowUturnLeftIcon}
          onClick={() => {
            messageStore.setReplyModel(messageModel);
            onClose();
          }}
        />
        {isMyMessage && (
          <MessageMenuButton
            label={t('edit')}
            Icon={PencilIcon}
            onClick={() => {
              messageStore.editMessage(messageModel);
              onClose();
            }}
          />
        )}
        {/* TODO: it won't be in MVP but it must be there */}
        {/* 
        <MessageMenuButton
          label={t('createLink')}
          Icon={HashTagMessageSVG}
          onClick={() => {
            onClose();
          }}
        /> */}
        {isMobile && messageModel.serverData.read && (
          <MessageMenuButton
            label={t('markAsUnread')}
            Icon={EyeSlashIcon}
            onClick={() => {
              messageStore.markMessageAsUnread(messageModel);
              onClose();
            }}
          />
        )}

        {isMobile && <Separator />}

        {isMyMessage && (
          <MessageMenuButton
            label={t('delete')}
            iconCn={isMobile ? '!text-element-error' : ''}
            labelCn={isMobile ? '!text-element-error' : ''}
            Icon={TrashIcon}
            onClick={() => {
              deleteMessageModalStoreLocal.show(messageModel);
              if (!isMobile) {
                onClose();
              }
            }}
          />
        )}

        {!isMobile && (
          <MessageMenuMore messageModel={messageModel} onClose={onClose} />
        )}
      </ul>
    </div>
  );
}

export const MessageMenu = observer(_MessageMenu);
