import { classNames } from '@foundationPathAlias/utilities';

export interface TooltipHoverContentProps {
  label: string;
  cn?: string;
}

export const TooltipHoverContentStyles = {
  tooltip: {
    border: 'none',
    boxShadow: 'none',
    width: 'auto',
    height: 24,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
};

const TooltipHoverContent = (props: TooltipHoverContentProps) => {
  const { label, cn } = props;
  return (
    <div
      className={classNames('px-[8px] text-sm13D text-text-primaryDark', cn)}
    >
      {label}
    </div>
  );
};

export { TooltipHoverContent };
