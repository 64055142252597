import {
  SquareIconButtonBase,
  Tooltip,
  TooltipHoverContent,
} from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

const responsiveBtnWH = 'h-[32px] w-[32px] md:h-[40px] md:w-[40px]';

type Props = {
  Icon: React.ElementType;
  onClick: () => void;
  label: string;
  getRef?: (ref: HTMLDivElement) => void;
  cn?: string;
  iconCn?: string;
  labelCn?: string;
};

export function MessageMenuButton(props: Props) {
  const { Icon, onClick, label, getRef, cn, iconCn, labelCn } = props;

  const {
    systemStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  if (isMobile) {
    return (
      <div
        onClick={onClick}
        className={classNames('flex h-[48px] flex-row items-center', cn)}
      >
        <Icon
          className={classNames(
            'duration-350 themed-text h-[20px] w-[20px] transition-all ease-in-out',
            iconCn
          )}
        />
        <span
          className={classNames('themed-text ml-[16px] text-body16R ', labelCn)}
        >
          {label}
        </span>
      </div>
    );
  }

  return (
    <div className={`relative ${responsiveBtnWH}`} ref={getRef}>
      <Tooltip
        offsetY={8}
        offsetX={16}
        darkMode={systemStore.isDarkTheme}
        triggerEl={
          <div>
            <SquareIconButtonBase
              onClick={onClick}
              className={classNames(
                `animation-classic group ${responsiveBtnWH}
              hover:bg-hovered-selected hover:opacity-100 dark:hover:bg-hovered-selected-dark
              `,
                cn
              )}
              iconWrapperCn="h-[32px] w-[32px] md:h-[24px] md:w-[24px]"
            >
              <Icon
                className={classNames(
                  'duration-350 h-[18px] w-[18px] fill-text-secondary text-text-secondary transition-all ease-in-out group-hover:fill-text-primary group-hover:text-text-primary dark:fill-text-secondary-dark dark:text-text-secondary-dark group-hover:dark:fill-text-primary-dark group-hover:dark:text-text-primary-dark md:h-[14px] md:w-[14px]',
                  iconCn
                )}
              />
            </SquareIconButtonBase>
          </div>
        }
      >
        <TooltipHoverContent label={label} cn={labelCn} />
      </Tooltip>
    </div>
  );
}
